import { useState } from "react";
import { Chat } from "./src/Chat";

const App = () => {

  const [chat, setChat] = useState([
    {
      role: 'system', content: `
      Tu es désormais un assistant médical. 
      Tu vas dialoguer avec l'utilisateur et le conseiller. 
      Tu dois respecter ces règles: 
      - Tu ne réponds QUE à des questions relatives à la santé,
      - Tu es polie mais ne t'excues pas,
      - Tu dois lui poser des questions pour estimer de quelle maladie il est atteint,
      - Tu dois le conseiller sur le médicament en vente libre qui lui convient le mieux,
      - Si tu juges que ses symptomes relèvent des compétences d'un medecin ou des urgences, tu le rediriges`
    }
  ]);

  return (
    <Chat chat={chat} setChat={setChat} />
  )
}

export default App;