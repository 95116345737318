import React, { useEffect, useRef, useState } from 'react';
import { View, Picker, Text, TouchableOpacity, StyleSheet, TextInput, ScrollView, useWindowDimensions, Animated } from 'react-native';
import { Entypo } from '@expo/vector-icons';

import { TypingAnimation } from 'react-native-typing-animation';
import OpenAI from 'openai';

const darkBlue = "#1450A3";
const blue = "#0A6EBD";

const Message = ({ el, i, mess, currentResponse }) => {

    const scale = useRef(new Animated.Value(0)).current;

    const animScale = () => {
        Animated.spring(scale, {
            bounciness: 14,
            toValue: 1,
            speed: 3,
            useNativeDriver: true,
        }).start()
    }

    return (
        <Animated.View
            onLayout={() => {
                if (el.type == 0) {
                    animScale();
                }
            }}
            style={{ maxWidth: "80%", marginTop: 10, padding: el.type == 2 ? 0 : 10, borderRadius: 10, backgroundColor: el.type == 0 ? blue : darkBlue, alignSelf: el.type == 0 ? "flex-end" : "flex-start", transform: [{ scale: el.type == 0 ? scale : 1 }] }}>
            {el.type == 2 && i == mess.length - 1 && currentResponse.length == 0 &&
                <View style={{ width: 50, height: 40, borderRadius: 10, backgroundColor: darkBlue }}>
                    <TypingAnimation
                        dotColor={"white"}
                        dotMargin={5}
                        dotAmplitude={3}
                        dotSpeed={0.15}
                        dotRadius={4}
                        dotX={12}
                        dotY={8}
                        style={{ position: "absolute", top: 10, left: 10 }}
                    />
                </View>
            }
            <Text style={{ fontWeight: "500", color: "white" }}>{el.content}</Text>
        </Animated.View>
    )
}

export const Chat = ({ chat, setChat }) => {

    const [query, setQuery] = useState("");

    const [mess, setMess] = useState([]);
    const [currentResponse, setCurrentResponse] = useState("");
    const [openai, setOpenai] = useState();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const config = new OpenAI({
            apiKey: "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",
            dangerouslyAllowBrowser: true
        });
        setOpenai(config);
    }, [])

    useEffect(() => {
        const gatherResponse = async () => {
            setMess((old) => [...old, { type: 2, content: "" }])

            const stream = await openai.chat.completions.create({
                model: 'gpt-4',
                messages: chat,
                stream: true,
            });

            setLoading(false);

            var localCurrent = "";
            for await (const part of stream) {
                console.log(part.choices[0]?.delta?.content || '');
                localCurrent += part.choices[0]?.delta?.content || '';
                setCurrentResponse((old) => old + part.choices[0]?.delta?.content || '');
            }
            setMess((old) => [...old, { type: 1, content: localCurrent }]);
            setCurrentResponse("");
        }

        if (mess.length != 0 && mess[mess.length - 1].type == 0) {
            gatherResponse();
        }
    }, [chat])

    useEffect(() => {
        if (mess.length != 0 && mess[mess.length - 1].type == 0) {
            setLoading(true)
            setChat((old) => [...old, {
                "role": "user",
                "content": mess[mess.length - 1].content,
            }])
        }
    }, [mess.length])

    const messScrollRef = useRef(null)
    const textOpacity = useRef(new Animated.Value(1)).current;
    const hideText = () => {
        Animated.timing(textOpacity, {
            duration: 800,
            toValue: 0,
            useNativeDriver: true,
        }).start(() => {
            setMess((old) => [...old, { type: 0, content: query }])
        })
    }


    return (
        <View style={{ width: "100vw", alignItems: "center", height: "100vh", justifyContent: "center" }}>
            {textOpacity._value != 0 && currentResponse.length == 0 && <Animated.Text style={{ fontSize: 45, textAlign: "center", fontWeight: "bold", opacity: textOpacity, color: darkBlue }}>Je suis Iris, decrivez moi vos symptomes</Animated.Text>}
            {textOpacity._value == 0 && <ScrollView onContentSizeChange={() => messScrollRef.current.scrollToEnd({ animated: true })} ref={messScrollRef} style={{
                minWidth: 400,
                maxWidth: 600,
                width: "50vw",
                marginTop: 50,
                paddingHorizontal: 15,
                paddingBottom: 10,
                height: textOpacity._value == 0 ? "calc(100vh - 80px - 77px - 100px)" : null // top bar (80px) | search bar (57px + 20px de margin)
            }}
                contentContainerStyle={{
                    flexGrow: 1,
                    justifyContent: 'flex-end',
                }}
            >
                {
                    mess.map((el, i) => {
                        return <Message key={i} el={el} i={i} mess={mess} currentResponse={currentResponse} />
                    })
                }

                {textOpacity._value == 0 && currentResponse.length > 0 && <View style={{ maxWidth: "80%", marginTop: 10, padding: 10, borderRadius: 10, backgroundColor: darkBlue, alignSelf: "flex-start" }}>
                    <Text style={{ fontWeight: "500", color: "white" }}>{currentResponse}</Text>
                </View>}

            </ScrollView>}
            <View style={{ flexDirection: "row", marginTop: 50 }}>
                <Entypo name="magnifying-glass" size={26} color={blue} style={{ position: "absolute", top: 14, left: 14 }} />
                <TextInput
                    editable={!loading}
                    placeholder={textOpacity._value == 0 ? "Entrez votre réponse ici..." : "Entrez votre question ici..."}
                    value={query}
                    onChange={(e) => setQuery(e.nativeEvent.text)}
                    placeholderTextColor={darkBlue}
                    style={{
                        marginBottom: 20,
                        outline: "none",
                        padding: 20,
                        paddingLeft: 20 + 30,
                        borderRadius: 20,
                        minWidth: 400,
                        maxWidth: 600,
                        width: "50vw",
                        shadowColor: "black",
                        shadowOffset: {
                            width: 0,
                            height: 0,
                        },
                        shadowOpacity: 0.4,
                        shadowRadius: 7,
                        fontWeight: "500"
                    }}
                    onSubmitEditing={() => {
                        if (textOpacity._value == 1) {
                            setQuery("");
                            hideText();
                        } else {
                            setQuery("");
                            setMess((old) => [...old, { type: 0, content: query }])
                        }
                    }}
                />
            </View>
        </View>
    )
}